/* eslint-disable prefer-destructuring */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import lod_ from "lodash";
import { t } from "i18next";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";

import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import MDInput from "components/Basics/MDInput";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import {
	Autocomplete,
	Checkbox,
	FormControl,
	Icon,
	InputLabel,
	Menu,
	MenuItem,
	Select,
	Tooltip
} from "@mui/material";

import C from "../../constants";

/**
 * Parse dictionary to get available items by type
 * @param {[String]} value like : ["number"]
 */
export function parseTypeDictionary(arrayValues, dictionary) {
	let availableItems = {};
	// Map key of object
	for (let key in dictionary) {
		let actualLevel = dictionary[key];
		if (actualLevel.type === "level") {
			// Branch
			let result = parseTypeDictionary(arrayValues, actualLevel.items);
			// If we have some results we add the branch to the return
			if (!lod_.isEmpty(result)) {
				let copy = lod_.cloneDeep(actualLevel);
				copy.items = result;
				availableItems[key] = copy;
			}
		} else {
			// When item is sheet (end of the branch)
			if (arrayValues.includes(actualLevel.type)) {
				availableItems[key] = actualLevel;
			}
		}
	}
	return availableItems;
}

const TechnicalDatasDisplay = ({ chart, dictionary, handleChange }) => {
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);

	const display = chart?.display ?? {};
	const request = chart?.request ?? {};
	const binning = chart?.request?.binning ?? [];

	// anchors
	const [anchorRatingElement, setAnchorRatingElement] = useState(null);
	const [anchorZoneElement, setAnchorZoneElement] = useState(null);

	const [referenceValues, setReferenceValues] = useState([]);

	/**
	 * Update request's field
	 * @param {string} path - path to the field
	 * @param {any} value - value to set
	 * @returns {void}
	 */
	const updateRequest = (path, value) => {
		// Case when user delete a field
		if (lod_.isNil(value)) {
			handleChange(path, null, true);
			return;
		}

		let fieldInDic = value;

		if (path !== "request.reference.value") {
			fieldInDic = value.split(".");

			if (fieldInDic.length > 1) {
				fieldInDic = value.split(".").slice(1).join(".");
			} else {
				fieldInDic = fieldInDic[0];
			}
		}
		// Special case for attribute field
		if (path === "request.attribute") {
			let realPath = value.replaceAll(".", ".items.");
			let dicObject = lod_.get(dictionary, realPath);

			if (dicObject && dicObject.type === "timestamp") {
				handleChange("options.formatDate", true);
			} else {
				handleChange("options.formatDate", false);
			}
		}
		// Special case for row
		if (path === "request.row") {
			if (chart.type === "timeSeries") {
				handleChange("options.legend", request.row);
			}
		}

		handleChange(path, fieldInDic);
	};

	/**
	 * Get label from dictionary
	 * @param {string} attribute - attribute to get label from
	 * @returns {string} - label
	 */
	const getLabelFromDictionary = attribute => {
		let dic = dictionary[request.collection];
		if (dic) {
			let attributePath = attribute?.replaceAll(".", ".items.");
			let dicObject = lod_.get(dic.items, attributePath);
			if (dicObject) {
				return dicObject.label.fr;
			}
		}
		return t("SETTINGS.CHARTS.NEW.noSelection");
	};

	/**
	 * Reload reference values for ranking chart
	 * @param {*} e
	 * @returns
	 */
	const reloadReferenceValues = async e => {
		if (!request?.collection || !request?.reference?.target) return;

		let config = {
			method: "POST",
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_AMBACK}/api/v1/ressource/${request.collection}/dynamic?search=${e}&page=1&limit=10`,
			data: {
				/* filter: actionFind?.filter, */ searchFields: [request.reference.target],
				attribute: request.reference.target
			},
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: profile.assistantID,
				"Content-Type": "application/json"
			}
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});

		let results = result.results;
		results = results.map(r => {
			return lod_.get(r, request.reference.target);
		});
		// Filter multiple values
		results = lod_.uniq(results);

		setReferenceValues(results);
		// return result?.results || [];
	};

	return (
		<MDBox mt={2}>
			{/* Compute method choice */}
			<MDBox flex="1">
				<MDTypography variant="h6">
					{t("SETTINGS.CHARTS.NEW.computeMethodSelection")}
					<span className="mandatoryField">*</span>
				</MDTypography>
				<MDBox>
					<FormControl fullWidth>
						<InputLabel>{t("SETTINGS.select")}</InputLabel>
						<Select
							label={t("SETTINGS.select")}
							disabled={!request.collection}
							labelId="select-label"
							id="select"
							value={request.computeMethod}
							onChange={e => {
								let method = C.COMPUTE_METHOD.find(m => m.code === e.target.value);
								if (!lod_.isNil(method)) {
									handleChange("request.computeMethod", method.code);
									handleChange("options.formatDate", false);
								}
							}}
						>
							{C.COMPUTE_METHOD.map((method, index) => (
								<MenuItem key={index} value={method.code}>
									<MDBox>
										<MDBox display="flex" alignItems="center">
											<MDBox display="flex" alignItems="center" mr={1}>
												<Icon>{method.icon}</Icon>
											</MDBox>
											<MDTypography variant="h6">{t(method.label)}</MDTypography>
										</MDBox>
										<MDTypography variant="body2">{t(method.description)}</MDTypography>
									</MDBox>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBox>
			</MDBox>
			{/*
			 * Attribute field
			 * For all charts except count
			 */}
			{request.computeMethod !== "COUNT" && (
				<FieldInput
					required
					info={t("SETTINGS.CHARTS.NEW.computeAttributeInfo")}
					getLabelFromDictionary={getLabelFromDictionary}
					request={request}
					disabled={!request.computeMethod}
					title={t("SETTINGS.CHARTS.NEW.computeAttribute")}
					field="attribute"
					dictionary={dictionary}
					updateRequest={updateRequest}
				/>
			)}
			{/*
			 * Filter date field
			 * For all charts
			 */}
			<FieldInput
				info={t("SETTINGS.CHARTS.NEW.filterDateAttributeInfo")}
				getLabelFromDictionary={getLabelFromDictionary}
				request={request}
				disabled={!request.computeMethod}
				title={t("SETTINGS.CHARTS.NEW.filterDateAttribute")}
				field="filterDateAttribute"
				dictionary={parseTypeDictionary(["date", "datetime"], dictionary)}
				updateRequest={updateRequest}
			/>
			{/*
			 * Onevalue ranking
			 */}
			{["ranking"].includes(chart.display.subType) && (
				<MDBox mt={2}>
					{/* Title */}
					<MDTypography variant="h6">
						{t("CHARTS.RANKING.referenceField")}
						<span className="mandatoryField">*</span>
					</MDTypography>
					{/* Selection */}
					<MDBox
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="stretch"
					>
						{/* Input dictionary */}
						<MDBox
							flex="1"
							className="boxInputStyle"
							borderRadius="md"
							style={{
								borderRadius: "0.375rem 0 0 0.375rem"
							}}
						>
							{getLabelFromDictionary(request?.reference?.target)}
						</MDBox>
						<MDBox
							className="endButtonboxInputStyle"
							display="flex"
							justifyContent="center"
							alignItems="center"
							bgColor="light"
							onClick={e => {
								if (!request.computeMethod) return;
								setAnchorRatingElement(e.currentTarget);
							}}
						>
							<Icon fontSize="small">menu</Icon>
						</MDBox>
						{/* Input value */}
						<Autocomplete
							disabled={!request.computeMethod || !request?.reference?.target}
							sx={{ flex: 1, ml: 1 }}
							id="reference-value"
							name="reference-value"
							options={referenceValues}
							onChange={(e, newValue) => {
								updateRequest("request.reference.value", newValue);
							}}
							defaultValue={request?.reference?.value}
							renderInput={params => (
								<MDInput
									{...params}
									label={t("CHARTS.RANKING.optionnalValue")}
									onChange={e => reloadReferenceValues(e.target.value)}
								/>
							)}
						/>

						<DictionaryMenu
							placement="right"
							dictionary={dictionary}
							anchorEl={anchorRatingElement}
							handleInsertText={e => {
								setAnchorRatingElement(null);
								updateRequest("request.reference.target", e);
								reloadReferenceValues();
							}}
							handleClose={() => setAnchorRatingElement(null)}
						/>
					</MDBox>
				</MDBox>
			)}
			{/*
			 * Onevalue ranking
			 */}
			{["ranking"].includes(chart.display.subType) && (
				<MDBox mt={2}>
					{/* Title */}
					<MDTypography variant="h6">{t("CHARTS.RANKING.complementaryDatas")}</MDTypography>

					<MDTypography variant="caption">{t("CHARTS.RANKING.displayValues")}</MDTypography>
					<MDBox
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
					>
						{/* Min */}
						<MDBox
							flex="1"
							p={0.5}
							mr={0.5}
							display="flex"
							flexDirection="row"
							alignItems="center"
							borderRadius="md"
							style={{
								border: "1px solid #e0e0e0",
								cursor: "pointer",
								userSelect: "none"
							}}
							onClick={() => {
								handleChange("display.extra.min.active", !display.extra?.min?.active);
							}}
						>
							<Checkbox checked={Boolean(display.extra?.min?.active)} />
							<MDTypography variant="button">{t("CHARTS.RANKING.global")}</MDTypography>
						</MDBox>
						{/* Max */}
						<MDBox
							flex="1"
							p={0.5}
							display="flex"
							flexDirection="row"
							alignItems="center"
							borderRadius="md"
							style={{
								border: "1px solid #e0e0e0",
								cursor: "pointer",
								userSelect: "none"
							}}
							onClick={() => {
								handleChange("display.extra.max.active", !display.extra?.max?.active);
							}}
						>
							<Checkbox checked={Boolean(display.extra?.max?.active)} />
							<MDTypography variant="button">
								{t("SETTINGS.CHARTS.COMPUTE.METHODS.max")}
							</MDTypography>
						</MDBox>
						{/* Global */}
						<MDBox
							flex="1"
							p={0.5}
							ml={0.5}
							display="flex"
							flexDirection="row"
							alignItems="center"
							borderRadius="md"
							style={{
								border: "1px solid #e0e0e0",
								cursor: "pointer",
								userSelect: "none"
							}}
							onClick={() => {
								handleChange("display.extra.global.active", !display.extra?.global?.active);
							}}
						>
							<Checkbox checked={Boolean(display.extra?.global?.active)} />
							<MDTypography variant="button">
								{t("SETTINGS.CHARTS.COMPUTE.METHODS.min")}
							</MDTypography>
						</MDBox>
					</MDBox>
					{/* New zone */}
					<MDBox>
						{/* Title */}
						<MDTypography variant="caption">{t("CHARTS.RANKING.addZone")}</MDTypography>
						{/* Selection */}
						<MDBox
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="stretch"
						>
							{/* Input dictionary */}
							<MDBox
								flex="1"
								className="boxInputStyle"
								borderRadius="md"
								style={{
									borderRadius: "0.375rem 0 0 0.375rem"
								}}
							>
								{getLabelFromDictionary(request?.reference?.zone)}
							</MDBox>
							<MDBox
								className="endButtonboxInputStyle"
								display="flex"
								justifyContent="center"
								alignItems="center"
								bgColor="light"
								onClick={e => {
									updateRequest("request.reference.zone", null);
								}}
								style={{
									borderRadius: "0"
								}}
							>
								<Icon fontSize="small">close</Icon>
							</MDBox>
							<MDBox
								className="endButtonboxInputStyle"
								display="flex"
								justifyContent="center"
								alignItems="center"
								bgColor="light"
								onClick={e => {
									if (!request.computeMethod) return;
									setAnchorZoneElement(e.currentTarget);
								}}
							>
								<Icon fontSize="small">menu</Icon>
							</MDBox>
							{/* Input value */}
							<DictionaryMenu
								placement="right"
								dictionary={dictionary}
								anchorEl={anchorZoneElement}
								handleInsertText={e => {
									setAnchorZoneElement(null);
									updateRequest("request.reference.zone", e);
								}}
								handleClose={() => setAnchorZoneElement(null)}
							/>
						</MDBox>
					</MDBox>
				</MDBox>
			)}
			{/*
			 * Group field
			 * For oneDimension / proportion charts
			 */}
			{(["oneDimension", "proportion"].includes(chart.type) ||
				["sortedList"].includes(chart.display.subType)) && (
				<FieldInput
					required={chart.display.subType !== "sortedList"}
					info={t("SETTINGS.CHARTS.NEW.groupByInfo")}
					getLabelFromDictionary={getLabelFromDictionary}
					request={request}
					disabled={!request.computeMethod}
					title={t("SETTINGS.CHARTS.NEW.groupBy")}
					field="group"
					dictionary={dictionary}
					updateRequest={updateRequest}
				/>
			)}
			{/*
			 * Col field
			 * For crossTable
			 */}
			{["crossTable"].includes(chart.type) && (
				<FieldInput
					required
					info={t("SETTINGS.CHARTS.NEW.abcisseSelectionInfo")}
					getLabelFromDictionary={getLabelFromDictionary}
					request={request}
					disabled={!request.computeMethod}
					title={t("SETTINGS.CHARTS.NEW.abcisseSelection")}
					field="col"
					dictionary={dictionary}
					updateRequest={updateRequest}
				/>
			)}
			{/*
			 * Bining fields
			 * For timeSeries
			 */}
			{["timeSeries"].includes(chart.type) && (
				<>
					<FieldInput
						required
						info={t("SETTINGS.CHARTS.NEW.dateAttributeSelectionInfo")}
						getLabelFromDictionary={getLabelFromDictionary}
						request={request}
						disabled={!request.computeMethod}
						title={t("SETTINGS.CHARTS.NEW.dateAttributeSelection")}
						field="dateAttribute"
						dictionary={parseTypeDictionary(["datetime", "date"], dictionary)}
						updateRequest={updateRequest}
					/>
					<MDBox mt={2}>
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="h6">
								{t("SETTINGS.CHARTS.NEW.binningSelection")}
								<span className="mandatoryField">*</span>
							</MDTypography>
							<MDBox ml={1} display="flex" alignItems="center">
								<Tooltip title={t("SETTINGS.CHARTS.NEW.binningSelectionInfo")} placement="top">
									<Icon>info</Icon>
								</Tooltip>
							</MDBox>
						</MDBox>
						<BinningInput binning={binning} handleChange={handleChange} />
					</MDBox>
				</>
			)}
			{/*
			 * Row field
			 * For crossTable / timeSeries
			 */}
			{["crossTable", "timeSeries"].includes(chart.type) && (
				<FieldInput
					info={t("SETTINGS.CHARTS.NEW.ordinateSelectionInfo")}
					getLabelFromDictionary={getLabelFromDictionary}
					request={request}
					disabled={!request.computeMethod}
					title={t("SETTINGS.CHARTS.NEW.ordinateSelection")}
					field="row"
					dictionary={dictionary}
					updateRequest={updateRequest}
				/>
			)}
		</MDBox>
	);
};

const BinningInput = ({ binning, handleChange }) => {
	const [binningMenuEl, setBinningMenuEl] = useState(null);

	const matchingBinning = C.BINNING_LIST.find(b => lod_.isEqual(b.binning, binning));

	return (
		<>
			<MDBox
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="stretch"
				style={{ cursor: "pointer" }}
				onClick={e => setBinningMenuEl(e.target)}
			>
				<MDBox
					flex="1"
					className="boxInputStyle"
					borderRadius="md"
					style={{
						borderRight: "none",
						borderRadius: "0.375rem 0 0 0.375rem"
					}}
				>
					{matchingBinning ? (
						<MDTypography variant="body1" fontSize="small">
							{matchingBinning.name}
						</MDTypography>
					) : (
						<MDTypography variant="body1" fontSize="small" style={{ opacity: 0.7 }}>
							{t("SETTINGS.select")}
						</MDTypography>
					)}
				</MDBox>
				{/* Open dictionary menu */}
				<MDBox
					className="endButtonboxInputStyle"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Icon fontSize="small">keyboard_arrow_down</Icon>
				</MDBox>
			</MDBox>
			{/* Menu */}
			<Menu
				open={Boolean(binningMenuEl)}
				anchorEl={binningMenuEl}
				onClose={() => setBinningMenuEl(null)}
			>
				{C.BINNING_LIST.map((binning, index) => {
					return (
						<MenuItem
							key={index}
							onClick={() => {
								handleChange("request.binning", binning.binning);
								setBinningMenuEl(null);
							}}
						>
							{binning.name}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

const FieldInput = ({
	info = null,
	getLabelFromDictionary,
	request,
	disabled = false,
	title,
	field,
	dictionary,
	updateRequest,
	required = false
}) => {
	const [anchorElOutput, setAnchorElOutput] = useState(null);

	return (
		<MDBox mt={2}>
			<MDBox display="flex" alignItems="center">
				<MDTypography variant="h6">
					{title}
					{required && <span className="mandatoryField">*</span>}
				</MDTypography>
				{info && (
					<MDBox ml={1} display="flex" alignItems="center">
						<Tooltip title={info} placement="top">
							<Icon>info</Icon>
						</Tooltip>
					</MDBox>
				)}
			</MDBox>

			<MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch">
				<MDBox
					flex="1"
					className="boxInputStyle"
					borderRadius="md"
					style={{
						borderRadius: "0.375rem 0 0 0.375rem"
					}}
				>
					{getLabelFromDictionary(lod_.get(request, field))}
				</MDBox>
				{/* Clear value for not required fields */}
				{!required && (
					<Tooltip placement="top" title={t("SETTINGS.remove")}>
						<MDBox
							className="endButtonboxInputStyle"
							display="flex"
							justifyContent="center"
							alignItems="center"
							bgColor="light"
							onClick={e => updateRequest(`request.${field}`, null)}
							style={{
								borderRadius: "0"
							}}
						>
							<Icon fontSize="small">close</Icon>
						</MDBox>
					</Tooltip>
				)}
				{/* Open dictionary menu */}
				<Tooltip placement="top" title={t("SETTINGS.select")}>
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						onClick={e => setAnchorElOutput(e.target)}
					>
						<Icon fontSize="small">menu</Icon>
					</MDBox>
				</Tooltip>
				{/* Dictionary menu */}
				<DictionaryMenu
					placement="right"
					dictionary={dictionary}
					anchorEl={anchorElOutput}
					handleInsertText={e => {
						setAnchorElOutput(null);
						updateRequest(`request.${field}`, e);
					}}
					handleClose={() => setAnchorElOutput(null)}
				/>
			</MDBox>
		</MDBox>
	);
};

export default TechnicalDatasDisplay;
