/* eslint-disable import/order */
import lod_ from "lodash";
import { Icon } from "@mui/material";
import MDButton from "components/Basics/MDButton";
import FilterTableLine from "./FilterTableLine";
import C from "../constants";
import MDTypography from "components/Basics/MDTypography";
import MDBox from "components/Basics/MDBox";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { useState } from "react";
import { t } from "i18next";

const FiltersTable = ({
	disabled,
	filtersArray,
	handleRemoveFilter,
	onChangeValue,
	onClose,
	handleAddFilter,
	dictionary
}) => {
	const [anchorGroupEl, setAnchorGroupEl] = useState(null);
	// Get all possibles methods for a given type
	function getMethod(type) {
		switch (type) {
			case "string":
				return C.COMP_STRING;
			case "number":
				return C.COMP_NUMBER;
			case "code":
				return C.COMP_CODE;
			case "date":
			case "datetime":
			case "timestamp":
				return C.COMP_DATE;
			default:
				return [];
		}
	}

	return (
		<MDBox
			style={{ height: "100%" }}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<MDBox flex="1">
				{/* Title */}
				<MDTypography variant="h5" mb={2}>
					{t("CHARTS.STEPS.DATAS.conditions")}
				</MDTypography>
				{/* List all conditions */}
				{lod_.isNil(filtersArray) ||
					(lod_.isEmpty(filtersArray) && (
						<MDBox display="flex" justifyContent="center" mb={3}>
							<MDTypography variant="body2" color="textSecondary">
								{t("CHARTS.STEPS.DATAS.noCondition")}
							</MDTypography>
						</MDBox>
					))}
				{filtersArray.map((filter, index) => {
					return (
						<FilterTableLine
							name={filter.name}
							path={filter.path}
							value={filter.value}
							method={filter.method}
							filter={filter.filter}
							methods={getMethod(filter?.filter?.type)}
							key={index}
							handleRemoveFilter={handleRemoveFilter}
							onChangeValue={onChangeValue}
							dictionary={dictionary}
						/>
					);
				})}
				{/* Add a condition */}
				<MDBox style={{ width: "100%" }} display="flex" justifyContent="center">
					<MDButton
						disabled={disabled}
						size="small"
						variant="outlined"
						color="info"
						onClick={e => setAnchorGroupEl(e.target)}
					>
						<Icon>add</Icon>&nbsp;{t("CHARTS.STEPS.DATAS.addACondition")}
					</MDButton>
				</MDBox>
				<DictionaryMenu
					placement="right"
					dictionary={dictionary}
					anchorEl={anchorGroupEl}
					handleInsertText={path => {
						setAnchorGroupEl(null);
						handleAddFilter(path);
					}}
					handleClose={() => setAnchorGroupEl(null)}
					clickableLevels={false}
				/>
			</MDBox>
			<MDBox display="flex" justifyContent="end">
				<MDButton variant="contained" color="info" onClick={onClose}>
					{t("SETTINGS.close")}
				</MDButton>
			</MDBox>
		</MDBox>
	);
};

export default FiltersTable;
