import { useState } from "react";
import { Grid } from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import ChartEditingOrder from "../Components/ChartEditingOrder";
import OneValueChart from ".";

/**
 * Display one value charts
 * @param {*} param0
 * @returns
 */
const OneValueDisplay = ({
	charts,
	setCharts,
	pageEditMode,
	getChartsData,
	dragHandler,
	handleEditChart,
	handleDeleteChart
}) => {
	const [hoveredChart, setHoveredChart] = useState(null);

	const editingMouseInCard = code => {
		setHoveredChart(code);
	};

	const editingMouseOutCard = code => {
		setHoveredChart(null);
	};

	const isHovered = code => {
		return hoveredChart === code;
	};

	return (
		<MDBox display="flex" flexDirection="row" mt={4}>
			{pageEditMode && (
				<ChartEditingOrder
					getChartsData={getChartsData}
					type={["oneValue"]}
					dragHandler={dragHandler}
					handleEditChart={handleEditChart}
					mouseIn={editingMouseInCard}
					mouseOut={editingMouseOutCard}
					handleDeleteChart={handleDeleteChart}
				/>
			)}
			<MDBox
				flex="5"
				style={{
					width: "100%",
					overflowX: "auto"
				}}
			>
				<Grid mt={0.5} container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
					{getChartsData(["oneValue"]).map((chart, index) => {
						/*  Get the first data object, because it has to be the only one */
						return (
							<OneValueChart
								id={chart.code}
								key={index}
								chart={chart}
								data={chart.data}
								dictionary={chart.dictionary ?? {}}
								xs={2}
								md={2}
								lg={2}
								xxl={chart.display?.width ?? 2}
								handleEditChart={handleEditChart}
								resizeChart={(code, size) => {
									let clonedCharts = lod_.cloneDeep(charts);
									let index = clonedCharts.findIndex(chart => chart.code === code);
									clonedCharts[index].display.width = size;
									setCharts(clonedCharts);
								}}
								style={{
									filter: isHovered(chart.code) ? "brightness(0.8)" : ""
								}}
							/>
						);
					})}
				</Grid>
			</MDBox>
		</MDBox>
	);
};

export default OneValueDisplay;
