/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Charts settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Menu,
	MenuItem,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import lod_ from "lodash";
import { t } from "i18next";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import ChartsActions from "redux-react/actions/chartsActions";
import SettingsActions from "redux-react/actions/settingsActions";
import { display } from "redux-react/reducers/snackBarReducer";
import { socket } from "redux-react/middleware/ws";
import { useSelector, useDispatch } from "react-redux";
import { createCode } from "../filters";
import ChartChoiceStep from "./steps/1. ChartChoiceStep";
import SubTypeChoiceStep from "./steps/2. SubTypeChoiceStep";
import DataChoiceStep from "./steps/3. DataChoiceStep";
import DisplayChoiceStep from "./steps/4. DisplayChoiceStep";

export default function AddChartDialog({
	open,
	handleCloseDialog,
	handleSave,
	edit = false,
	chart: propsChart = {},
	defaultValues = {},
	step = 0,
	code = null
}) {
	const assistantID = useSelector(state => selectCurrentProfile(state).assistantID);
	const profile = useSelector(state => selectCurrentProfile(state));
	const topContainer = useRef(null);
	const dispatch = useDispatch();

	const [collections, setCollections] = useState([]);
	const [loading, setLoading] = useState(true);

	function getCorrectChart() {
		if (edit) {
			return propsChart;
		} else {
			let defaultChart = {
				documentType: "chart",
				assistantID,
				code: createCode(assistantID),
				display: {
					icon: {
						component: "equalizer",
						color: "info"
					},
					pages: []
				},
				request: {
					collection: ""
				},
				filters: {},
				options: {
					legend: true
				},
				active: true
			};

			Object.entries(defaultValues).forEach(([key, value]) => {
				lod_.set(defaultChart, key, value);
			});

			return defaultChart;
		}
	}
	// Chart skeleton
	const [newChart, setNewChart] = useState({});
	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.1"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.2"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.3"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.4")
	]);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === steps.length - 1;
	/**
	 * Close modale
	 */
	function close(e, reason) {
		handleCloseDialog();
		/**
		 * On component close, reset all states
		 * Timeout to wait for animation end
		 */
		setTimeout(() => {
			setNewChart(prev => {
				return {
					documentType: "chart",
					assistantID,
					code: createCode(assistantID),
					display: {
						icon: {
							component: "equalizer",
							color: "info"
						},
						pages: []
					},
					request: {
						collection: ""
					},
					filters: {},
					options: {
						legend: true
					},
					active: true
				};
			});
			setStepValid(false);
			setActiveStep(0);
			setSteps([
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.1"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.2"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.3"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.4")
			]);
		}, 400);
	}
	/**
	 * Submit chart
	 */
	function sumbit() {
		let clonedChart = lod_.cloneDeep(newChart);
		delete clonedChart.editMode;
		handleSave({ chart: clonedChart, edit: edit });
		close();
	}
	/**
	 * User select chart type
	 */
	const handleSelectChart = chart => {
		setNewChart(prev => {
			let copy = lod_.cloneDeep(prev);
			copy.request = {
				collection: ""
			};
			copy.filters = {};
			copy.options = {
				legend: true
			};
			copy.type = chart.type;
			return copy;
		});

		setSteps([
			`${i18n.t("SETTINGS.CHARTS.NEW.STEPS.1")} (${i18n.t(chart.title)})`,
			...steps.slice(1, steps.length)
		]);
	};
	/**
	 * User select chart subType
	 */
	const handleSelectSubType = subType => {
		setNewChart(prev => {
			let copy = lod_.cloneDeep(prev);
			delete copy.display.extra;

			copy.request = {
				collection: ""
			};
			copy.filters = {};
			copy.options = {
				legend: true
			};
			copy.display.subType = subType.code;

			if (subType.code === "sortedList") {
				copy.request.other = false;
				copy.request.limit = 5;
				copy.request.sort = "desc";
				copy.request.attributesDisplay = [];
			}

			if (subType.code === "ranking") {
				copy.display.extra = {
					global: {
						active: true
					},
					max: {
						active: true
					},
					min: {
						active: true
					}
				};
			}

			return copy;
		});

		setSteps([
			steps[0],
			`${i18n.t("SETTINGS.CHARTS.NEW.STEPS.2")} (${i18n.t(subType.title)})`,
			...steps.slice(2, steps.length)
		]);
	};

	const handleChange = (path, value, deleteMode = false) => {
		setNewChart(prev => {
			let copy = lod_.cloneDeep(prev);
			if (deleteMode) {
				lod_.unset(copy, path);
			} else {
				lod_.set(copy, path, value);
			}
			return copy;
		});
	};

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Go to previous step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<ChartChoiceStep
						chart={newChart}
						handleSelectChart={handleSelectChart}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);
			case 1:
				return (
					<SubTypeChoiceStep
						chart={newChart}
						handleSelectSubType={handleSelectSubType}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);
			case 2:
				return (
					<DataChoiceStep
						chart={newChart}
						collections={collections}
						validStep={validStep}
						isStepValid={stepValid}
						handleChange={handleChange}
					/>
				);
			case 3:
				return (
					<DisplayChoiceStep
						chart={{ ...newChart, editMode: true }}
						setChart={setNewChart}
						validStep={validStep}
						edit={edit}
					/>
				);
			default:
				return null;
		}
	}

	/**
	 * Load a dataset
	 * @param {*} dataset
	 */
	function loadDataset(dataset) {
		setNewChart(chart => {
			let copy = lod_.cloneDeep(chart);
			copy.request.collection = dataset.config.collection;
			copy.filters = dataset.config.filters;
			return copy;
		});
	}

	function getActionButtonLabel() {
		if (isLastStep) {
			return i18n.t("SETTINGS.save");
		} else {
			return i18n.t("SETTINGS.CHARTS.NEW.STEPS.next");
		}
	}

	function loadCollections() {
		socket.emit("get_possible_collections_for_charts_editor", {});
	}

	function loadCollectionsResult(collectionsResult) {
		setCollections(collectionsResult);
	}

	useEffect(() => {
		if (open === true) {
			// Load the collections
			loadCollections();
		}

		if (assistantID) {
			setLoading(true);
			setActiveStep(step);
			if (!code) {
				setLoading(false);
				setNewChart(prev => getCorrectChart());
			} else {
				const onSuccess = res => {
					if (res.success) {
						setLoading(false);
						setNewChart(prev => res.chart);
						validStep(true);
					} else {
						dispatch(
							display({
								message: i18n.t("SETTINGS.CHARTS.ERROR.notFound"),
								type: "error"
							})
						);
					}
				};
				dispatch(ChartsActions.getChartByCode(profile.assistantID, code, onSuccess));
			}
		}

		if (open === false) {
			setLoading(true);
			setCollections([]);
		}
	}, [open, code]);

	useEffect(() => {
		socket.on("get_possible_collections_for_charts_editor_result", loadCollectionsResult);

		return () => {
			socket.off("get_possible_collections_for_charts_editor_result", loadCollectionsResult);
		};
	}, []);

	if (loading) {
		return null;
	}

	/**
	 * Main component
	 */
	return (
		<Dialog
			fullWidth
			maxWidth="xxxl"
			PaperProps={{
				sx: {
					height: "95%"
				}
			}}
			open={open}
			onClose={close}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>
				<MDBox
					style={{
						height: "100%"
					}}
				>
					{getStepContent(activeStep)}
				</MDBox>
			</DialogContent>
			<DialogActions
				style={{
					justifyContent: "space-between"
				}}
			>
				<MDBox>
					{activeStep === 2 && (
						<ActionsButtonForDataset chart={newChart} loadDataset={loadDataset} />
					)}
				</MDBox>
				<MDBox>
					<MDButton variant="outlined" color="info" onClick={close}>
						{i18n.t("SETTINGS.cancel")}
					</MDButton>
					<MDButton
						sx={{ ml: 1 }}
						disabled={activeStep === 0}
						variant="contained"
						color="light"
						onClick={handleBack}
					>
						{i18n.t("SETTINGS.CHARTS.NEW.STEPS.back")}
					</MDButton>
					<MDButton
						sx={{ ml: 1 }}
						disabled={!stepValid}
						variant="contained"
						color="info"
						onClick={!isLastStep ? handleNext : sumbit}
					>
						{getActionButtonLabel()}
					</MDButton>
				</MDBox>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Actions button for dataset, load, save a dataset
 * @param {*} param0
 * @returns
 */
const ActionsButtonForDataset = ({ chart, loadDataset }) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const [datasets, setDatasets] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	async function loadDatasets() {
		dispatch(
			SettingsActions.getSettings(profile.assistantID, "dataset", res => {
				setDatasets(res.documents);
			})
		);
	}

	async function saveDataset(name) {
		const data = {
			name: name,
			config: {
				collection: chart.request.collection,
				filters: chart.filters ?? {}
			}
		};
		dispatch(
			SettingsActions.newSetting(profile.assistantID, "dataset", data, res => {
				dispatch(
					display({
						type: "success",
						message: t("CHARTS.DATASET.datasetHasBeenSaved")
					})
				);
				loadDatasets();
			})
		);
	}

	async function deleteDataset(dataset) {
		dispatch(
			SettingsActions.deleteSetting(profile.assistantID, "dataset", { code: dataset.code }, res => {
				dispatch(
					display({
						message: t("CHARTS.DATASET.datasetHasBeenDeleted"),
						type: "success"
					})
				);
				loadDatasets();
			})
		);
	}

	useEffect(() => {
		loadDatasets();
	}, []);

	return (
		<MDBox>
			<MDButton
				variant="contained"
				color="info"
				onClick={e => {
					setAnchorEl(e.currentTarget);
				}}
			>
				{t("CHARTS.DATASET.loadDataset")}
			</MDButton>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => {
					setAnchorEl(null);
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				{datasets.map((dataset, index) => {
					return (
						<MenuItem
							key={index}
							onClick={() => {
								loadDataset(dataset);
								setAnchorEl(null);
							}}
						>
							<MDBox
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="space-between"
								style={{ width: "100%" }}
							>
								<MDTypography variant="body2" fontSize="small">
									{dataset.name}
								</MDTypography>

								<ConfirmDialogButton
									onConfirm={async (e, values) => {
										deleteDataset(dataset);
									}}
									component={
										<IconButton sx={{ ml: 1 }} size="small">
											<Icon fontSize="small">delete</Icon>
										</IconButton>
									}
									rowValues={dataset}
									displayAttribute="name"
								/>
							</MDBox>
						</MenuItem>
					);
				})}
			</Menu>
			<MDButton
				sx={{ ml: 1 }}
				variant="contained"
				color="info"
				onClick={() => {
					setDialogOpen(true);
				}}
				disabled={lod_.isEmpty(chart?.request?.collection)}
			>
				{t("CHARTS.DATASET.saveDataset")}
			</MDButton>
			{/* Modal name */}
			<DatasetDialogName
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false);
				}}
				onSave={name => {
					saveDataset(name);
					setDialogOpen(false);
				}}
			/>
		</MDBox>
	);
};

/**
 * Dataset dialog name
 * @param {*} param0
 * @returns
 */
const DatasetDialogName = ({ open, onClose, onSave }) => {
	const [name, setName] = useState("");

	useEffect(() => {
		setName("");
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>{t("CHARTS.DATASET.datasetTitleName")}</DialogTitle>
			<DialogContent>
				<MDInput
					variant="outlined"
					fullWidth
					placeholder={t("CHARTS.DATASET.datasetPlaceholderName")}
					value={name}
					onChange={e => setName(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={onClose}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={() => onSave(name)}
					disabled={lod_.isEmpty(name)}
				>
					{i18n.t("SETTINGS.save")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};
