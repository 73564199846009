/* eslint-disable prefer-destructuring */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useEffect, useMemo, useState } from "react";
import lod_ from "lodash";
import { t } from "i18next";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { Drawer, FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import i18n from "i18n";
import SettingsActions from "redux-react/actions/settingsActions";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import FiltersTable from "./FiltersTable";

/**
 * Get the label of an item in a dictionary with the profile language
 * @param {object} label - The label object from dictionary
 * @param {object} profile - The profile object
 * @returns {string} - The label in the profile language
 */
function getItemLabel(label = {}, profile) {
	let languagesKeys = Object.keys(label);

	try {
		let language = profile.language;
		if (languagesKeys.includes(language)) {
			return label[language];
		} else {
			return label[languagesKeys[0]];
		}
	} catch {
		return label[languagesKeys[0]];
	}
}

/**
 * Parse dictionary to get available items by type
 * @param {[String]} value like : ["number"]
 */
export function parseTypeDictionary(arrayValues, dictionary) {
	let availableItems = {};
	// Map key of object
	for (let key in dictionary) {
		let actualLevel = dictionary[key];
		if (actualLevel.type === "level") {
			// Branch
			let result = parseTypeDictionary(arrayValues, actualLevel.items);
			// If we have some results we add the branch to the return
			if (!lod_.isEmpty(result)) {
				let copy = lod_.cloneDeep(actualLevel);
				copy.items = result;
				availableItems[key] = copy;
			}
		} else {
			// When item is sheet (end of the branch)
			if (arrayValues.includes(actualLevel.type)) {
				availableItems[key] = actualLevel;
			}
		}
	}
	return availableItems;
}

/**
 * Main component
 */
const DataChoiceStep = ({ chart, handleChange, collections, isStepValid, validStep }) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const request = chart.request;
	const filters = chart.filters;

	const [dictionary, setDictionary] = useState({});

	const [filtersArray, setFiltersArray] = useState([]);

	const [openDrawer, setOpenDrawer] = useState(false);

	const DELETE_MAP = {
		collection: [
			"computeMethod",
			"attribute",
			"group",
			"binning",
			"row",
			"col",
			"filterDateAttribute",
			"dateAttribute",
			"filters"
		],
		computeMethod: ["attribute"]
	};

	const COLLECTIONS_SOURCE_MAP = {
		clusterClient: i18n.t("SETTINGS.CHARTS.dictionariesDatas"),
		catalog: i18n.t("SETTINGS.CHARTS.faibrikDatas")
	};

	/**
	 * Close the drawer
	 */
	const closeDrawer = () => {
		setOpenDrawer(false);
	};

	/**
	 * Map filters to array
	 * @param {*} filters
	 * @returns
	 */
	function mapFiltersDictionary(filters, tempDictionary = dictionary) {
		let array = [];
		let keys = Object.keys(filters);
		for (let key of keys) {
			let method = Object.keys(filters[key])[0];
			let value = filters[key][method];

			let dic = tempDictionary[chart.request.collection] ?? {};

			let dicObject = lod_.get(dic.items, key.replaceAll(".", ".items."));
			if (dicObject) {
				let filter = {
					filter: dicObject,
					method,
					value,
					path: `${chart.request.collection}.${key}`,
					name: key
				};
				array.push(filter);
			}
		}
		return array;
	}

	/**
	 * Load dictionary
	 * @param {string} codeDictionary - The code of the dictionary
	 * @returns {Promise} - The promise
	 */
	async function loadDictionary(codeDictionary) {
		return new Promise((resolve, reject) => {
			dispatch(
				SettingsActions.getDictionary(codeDictionary, res => {
					setDictionary({
						[codeDictionary]: res.dictionary
					});
					resolve({
						[codeDictionary]: res.dictionary
					});
				})
			);
		});
	}

	// Handle when choosing a data source
	const updateCollection = async collection => {
		let toDelete = DELETE_MAP.collection;

		let clonedCopy = lod_.cloneDeep(request);
		clonedCopy.collection = collection.codeDictionary;

		for (let key of toDelete) {
			delete clonedCopy[key];
		}

		handleChange("request", clonedCopy);
		handleChange("filters", {});

		// Request back to get the dictionary
		await loadDictionary(collection.codeDictionary);
	};

	/*
	 * Filters functions
	 */
	// When user update a filter, save it
	const onChangeValue = ({ name, path, filter, method, value }) => {
		// Need to convert value to string in the check because lodash isEmpty return true for all numbers
		if (!lod_.isNil(method) && !lod_.isNil(value) && !lod_.isEmpty(value?.toString())) {
			let absolutePath = name;
			if (path) {
				absolutePath = path.split(".").slice(1).join(".");
			}

			handleChange(`filters[${absolutePath}].${method}`, value);

			let updatedFilter = {
				filter,
				path,
				name,
				method,
				value
			};

			let updatedFiltersArray = filtersArray.map(f => {
				if (f.name === name) {
					return updatedFilter;
				} else {
					return f;
				}
			});

			setFiltersArray(updatedFiltersArray);
		}
	};
	// When user want to add a filter
	const handleAddFilter = filter => {
		let realPath = filter.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);
		let name = filter.split(".")[filter.split(".").length - 1];
		// user can add only one filter by attribute
		let existInArray = filtersArray.find(f => f.name === name);

		if (dicObject && !existInArray) {
			setFiltersArray(prev => {
				let copy = lod_.cloneDeep(prev ?? []);
				copy.push({
					filter: dicObject,
					path: filter,
					name
				});
				return copy;
			});
		}
	};

	// When user want to remove a filter
	const handleRemoveFilter = name => {
		setFiltersArray(prev => {
			return prev.filter(f => f.name !== name);
		});

		handleChange(`filters[${name}]`, null, true);
	};

	function checkStepValidity() {
		// Check is all is valid
		let isValid = true;

		if (lod_.isNil(request.collection)) {
			isValid = false;
		}

		validStep(isValid);
	}

	async function init() {
		let tempDictionary = {};

		// Load dictionary
		if (!lod_.isNil(chart.request?.collection) && !lod_.isEmpty(chart.request?.collection)) {
			tempDictionary = await loadDictionary(chart.request.collection);
		}
		// load filters
		if (!lod_.isNil(chart.filters)) {
			setFiltersArray(mapFiltersDictionary(chart.filters, tempDictionary));
		}
	}

	function mapMenuCollection(collections) {
		let menu = {};

		for (let collection of collections) {
			if (collection.storage) {
				if (!menu[collection.storage]) {
					menu[collection.storage] = [];
				}

				menu[collection.storage].push(collection);
			}
		}

		let reactMenuItems = [];
		for (let key in menu) {
			reactMenuItems.push(
				<MenuItem disabled style={{ opacity: 0.7 }}>
					<MDTypography variant="h6" fontSize="small">
						{COLLECTIONS_SOURCE_MAP[key]}
					</MDTypography>
				</MenuItem>
			);

			for (let collection of menu[key]) {
				reactMenuItems.push(
					<MenuItem value={collection.codeDictionary}>
						{getItemLabel(collection.label, profile)}
					</MenuItem>
				);
			}
		}

		return reactMenuItems;
	}

	async function loadDataset() {
		let collection = collections.find(c => c.codeDictionary === chart.request.collection);
		if (!lod_.isNil(collection)) {
			let loadedDictionary = await loadDictionary(chart.request.collection);
			setFiltersArray(mapFiltersDictionary(chart.filters, loadedDictionary));
		}
	}

	// When updating the chart at the root
	// (like loading dataset)
	useEffect(() => {
		loadDataset();
	}, [chart]);

	// On page load
	useEffect(() => {
		checkStepValidity();
		// Init component
		init();
	}, []);

	return (
		<MDBox
			display="flex"
			flexDirection="column"
			style={{
				height: "100%",
				width: "100%"
			}}
		>
			<MDBox display="flex" flexDirection="row">
				{/* Data source */}
				<MDBox flex="1" mr={1}>
					<MDTypography variant="h6">
						{i18n.t("SETTINGS.CHARTS.NEW.dataSourceSelection")}
						<span className="mandatoryField">*</span>
					</MDTypography>
					<MDBox>
						<FormControl fullWidth>
							<InputLabel>{t("SETTINGS.select")}</InputLabel>
							<Select
								label={t("SETTINGS.select")}
								labelId="select-label"
								id="select"
								value={request.collection}
								onChange={e => {
									let collection = collections.find(c => c.codeDictionary === e.target.value);
									updateCollection(collection);
								}}
								renderValue={key => {
									const collection = collections.find(c => c.codeDictionary === key);
									const label = getItemLabel(collection.label, profile);
									const storage = COLLECTIONS_SOURCE_MAP[collection.storage];
									return (
										<MDBox>
											<MDBox>
												<MDTypography variant="h6">{label}</MDTypography>
											</MDBox>
											<MDBox>
												<MDTypography variant="body2">{storage}</MDTypography>
											</MDBox>
										</MDBox>
									);
								}}
							>
								{mapMenuCollection(collections)}
							</Select>
						</FormControl>
					</MDBox>
				</MDBox>
				{/* Filtres */}
				<MDBox flex="1" display="flex" flexDirection="column" ml={1}>
					<MDTypography variant="h6">{t("CHARTS.STEPS.DATAS.filterDatas")}</MDTypography>
					<MDBox
						variant={!isStepValid ? "contained" : "gradient"}
						bgColor={!isStepValid ? "light" : "info"}
						p={1.5}
						borderRadius="md"
						style={{
							cursor: !isStepValid ? "" : "pointer"
						}}
						onClick={() => isStepValid && setOpenDrawer(true)}
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<MDBox display="flex" flexDirection="column">
							<MDBox>
								<MDTypography variant="h6" color={!isStepValid ? "black" : "white"}>
									{t("CHARTS.STEPS.DATAS.showConditions")}
								</MDTypography>
							</MDBox>
							<MDBox>
								<MDTypography variant="body2" color={!isStepValid ? "black" : "white"}>
									{t("CHARTS.STEPS.DATAS.addCondition")}
								</MDTypography>
							</MDBox>
						</MDBox>
						<MDBox mr={3}>
							<Icon fontSize="medium" color="white">
								open_in_new
							</Icon>
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox flex="1" mt={1} className="displayLeftTab">
				<MDBox
					className="dialogContentChartEditor fullHeight"
					p={2}
					borderRadius="md"
					style={{
						overflow: "auto"
					}}
				>
					{(lod_.isNil(request.collection) || lod_.isEmpty(request.collection)) && (
						<MDBox
							display="flex"
							justifyContent="center"
							alignItems="center"
							style={{ width: "100%" }}
						>
							<MDTypography variant="body1" color="textSecondary">
								{t("CHARTS.STEPS.DATAS.selectDatasource")}
							</MDTypography>
						</MDBox>
					)}
					{/* Datatable to display used datas */}
					{/* useMemo to reload the table only when collection or filters change */}
					{useMemo(
						() => (
							<MDBox flex="1">
								{request.collection && (
									<DefaultDataTable
										//
										list={{
											request: {
												collection: request.collection,
												attributes: []
											},
											filters: {},
											options: {}
										}}
										pagination={{
											defaultEntrie: 20,
											entriesPerPage: [15, 20, 40, 50, 100]
										}}
										noMargin
										noHeader
										filters={filters}
										canSearch
										table={{
											rows: [],
											columns: []
										}}
										display={[]}
										unknownColumns // Will fetch columns name dynamically
									/>
								)}
							</MDBox>
						),
						[request.collection, filters]
					)}
					{/* Drawer */}
					<Drawer
						anchor="right"
						open={openDrawer}
						onClose={() => closeDrawer()}
						style={{
							zIndex: 1300
						}}
						PaperProps={{
							sx: { width: "50%" }
						}}
					>
						<MDBox p={2} style={{ height: "100%" }}>
							<FiltersTable
								disabled={!isStepValid}
								filtersArray={filtersArray}
								handleRemoveFilter={handleRemoveFilter}
								onClose={closeDrawer}
								onChangeValue={onChangeValue}
								handleAddFilter={handleAddFilter}
								dictionary={dictionary}
							/>
						</MDBox>
					</Drawer>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default DataChoiceStep;
