import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Icon,
	IconButton
} from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import { useState } from "react";

const IconAccordion = ({ title, content, open = false, forceOpen = false, actions = null }) => {
	const [expanded, setExpanded] = useState(open);
	return (
		<Accordion expanded={expanded}>
			<AccordionSummary
				onClick={() => !forceOpen && setExpanded(!expanded)}
				expandIcon={
					forceOpen ? null : (
						<IconButton>
							<Icon>expand_more</Icon>
						</IconButton>
					)
				}
			>
				<MDTypography variant="overline">{title}</MDTypography>
			</AccordionSummary>
			<AccordionDetails
				style={{
					maxHeight: "40vh",
					overflow: "auto"
				}}
			>
				{content}
			</AccordionDetails>
			{actions && <AccordionActions>{actions}</AccordionActions>}
		</Accordion>
	);
};

export default IconAccordion;
