/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	updateAttributeDisplayPaginedList: (listCode, attributesList, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/updateAttributeDisplayPaginedList/${listCode}`,
					data: { attributesList },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getPageFilters: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/filters/${assistantID}/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getChartByCode: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/chart/${assistantID}/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	refreshPaginedList: (list, entriesParPage, page, route, search, sort, filters, onSuccess) => {
		let data = {
			isSystem: route?.system ?? false,
			entriesParPage,
			page,
			search,
			request: list.request,
			filters,
			sort
		};

		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/paginedList/pagination`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getPaginedList: (assistantID, route, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/paginedList/${assistantID}/${route.route.replace("/", "")}?isSystem=${route.system ?? false}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getCharts: (assistantID, route, onSuccess) => {
		let data = {
			filter: route.filter,
			isSystem: route.system ?? false
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/charts/${assistantID}/${route.route.replace("/", "")}}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	buildRawCharts: (charts, filters, mandatoryFilters, onSuccess) => {
		let data = {
			charts,
			filters,
			mandatoryFilters: mandatoryFilters ?? []
		};

		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: "/api/v1/charts",
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getPageCharts: (
		assistantID,
		route,
		filters,
		mandatoryFilters,
		filterByAgent,
		isPageSystem,
		onSuccess
	) => {
		let data = {
			filters,
			isSystem: isPageSystem,
			mandatoryFilters: mandatoryFilters ?? [],
			filterByAgent
		};

		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/pageCharts/${assistantID}/${route.replace("/", "")}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	/* Get list count elements */
	getListCount: (assistantID, collection, filters, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/list/count/${assistantID}/${collection}`,
					data: { filters },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	/* Get list by code */
	getListByCode: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/list/${assistantID}/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	}
};

export default actions;
