/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/**
 * #######################################################@
 *
 * Charts settings
 *
 * #######################################################@
 */
import "./style.css";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { Divider, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import SettingsActions from "redux-react/actions/settingsActions";
import { display, hide } from "redux-react/reducers/snackBarReducer";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import MDTypography from "components/Basics/MDTypography";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import AddChartDialog from "./AddChartDialog";

export default function SettingsCharts({ route }) {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// Page Charts
	const [paginedList, setPaginedList] = useState(null);
	// Add new chart dialog state
	const [addNewChart, setAddNewChart] = useState(false);

	const [reloadTable, setReloadTable] = useState(false);

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});
	// Load charts from back
	// Load pagined lists
	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList[0]);
		};

		dispatch(ChartsActions.getPaginedList(profile.assistantID, route, onSuccess));
	}
	// Handle save new chart
	const handleSaveNewChart = ({ chart, edit }) => {
		if (edit) {
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.CHARTS.SUCCESS.edit"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			dispatch(SettingsActions.editSetting(profile.assistantID, "chart", chart, onSuccess));
		} else {
			const onSuccess = res => {
				let code = chart.code;
				let page = chart.display.pages[0];
				dispatch(
					display({
						type: "info",
						message: i18n.t("SETTINGS.CHARTS.SUCCESS.add"),
						validation: (
							<>
								<MDButton
									variant="contained"
									color="info"
									sx={{ ml: 1 }}
									onClick={() => {
										dispatch(hide());
										let anchorRef = `#${code}`;
										navigate(`/${page}${anchorRef}`);
									}}
								>
									{i18n.t("SETTINGS.see")}
								</MDButton>
								<MDButton
									sx={{ ml: 1 }}
									onClick={() => {
										dispatch(hide());
									}}
								>
									{i18n.t("SETTINGS.close")}
								</MDButton>
							</>
						)
					})
				);
				setReloadTable(!reloadTable);
			};
			dispatch(SettingsActions.newSetting(profile.assistantID, "chart", chart, onSuccess));
		}
	};
	// Handle delete chart
	const actionDeleteHandle = values => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.CHARTS.SUCCESS.delete"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
		};
		dispatch(
			SettingsActions.deleteSetting(profile.assistantID, "chart", { code: values.code }, onSuccess)
		);
	};
	/* Get chart by code */
	const getChartByCode = code => {
		const onSuccess = res => {
			if (res.success) {
				setAddNewChart({ open: true, edit: true, chart: res.chart, step: 3 });
			} else {
				dispatch(
					display({
						message: i18n.t("SETTINGS.CHARTS.ERROR.notFound"),
						type: "error"
					})
				);
			}
		};
		dispatch(ChartsActions.getChartByCode(profile.assistantID, code, onSuccess));
	};

	/**
	 * Duplicate
	 */
	const duplicateProcessAction = values => {
		dispatch(
			FormActions.duplicateItem(
				{
					query: { code: values.code },
					collection: process.env.REACT_APP_APPNAME,
					updateFields: ["code"]
				},
				res => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateSuccess"),
							type: "success"
						})
					);
					setReloadTable(!reloadTable);
				},
				err => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateError"),
							type: "error"
						})
					);
				}
			)
		);
	};

	useEffect(() => {
		loadPaginedList();
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox display="flex">
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							color="info"
							onClick={() => setAddNewChart({ open: true, edit: false, chart: {}, step: 0 })}
						>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.CHARTS.add")}
						</MDButton>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<>
					<DefaultDataTable
						route={route}
						id={paginedList.code}
						reloadTable={reloadTable}
						dictionary={paginedList.dictionary}
						form={route.form}
						list={paginedList}
						pagination={paginedList.pagination}
						canSearch
						table={paginedList.data}
						display={paginedList.request.attributesDisplay}
						optionsAction="left"
						actions={[
							<IconButton
								handleclick={({ values }, e) => {
									setMenuValues(values);
									setMenuRef(e.currentTarget);
								}}
							>
								<Icon fontSize="medium">more_vert</Icon>
							</IconButton>
						]}
					/>
					{/* Menu actions selection */}
					<Menu
						open={Boolean(menuRef)}
						anchorEl={menuRef}
						onClose={() => {
							setMenuRef(null);
						}}
					>
						{/* Options */}
						<MenuItem disabled style={{ opacity: 1 }}>
							<MDTypography variant="caption">Edition</MDTypography>
						</MenuItem>
						<MenuItem
							disabled={menuValues.type === "multiCollection"}
							onClick={() => {
								getChartByCode(menuValues.code);
								setMenuRef(null);
							}}
						>
							<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
						</MenuItem>

						<MenuItem
							onClick={() => {
								duplicateProcessAction(menuValues);
								setMenuRef(null);
							}}
						>
							<Icon fontSize="medium">copy</Icon>&nbsp;{i18n.t("SETTINGS.duplicate")}
						</MenuItem>

						<ConfirmDialogButton
							onConfirm={(e, values) => {
								actionDeleteHandle(values);
								setMenuRef(null);
							}}
							component={
								<MenuItem>
									<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
								</MenuItem>
							}
							rowValues={menuValues}
							displayAttribute="display.title"
						/>
					</Menu>
				</>
			)}
			{paginedList && (
				<AddChartDialog
					collections={paginedList.collections}
					open={addNewChart.open}
					handleCloseDialog={() => setAddNewChart({ open: false })}
					handleSave={handleSaveNewChart}
					edit={addNewChart.edit}
					chart={addNewChart.chart}
					step={addNewChart.step}
				/>
			)}
		</DashboardLayout>
	);
}
