/* eslint-disable no-unreachable */
/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import { cloneElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import i18n from "i18n";
import SettingsActions from "redux-react/actions/settingsActions";
import FormActions from "redux-react/actions/formAction";
import FormDictionaryDialog from "components/Custom/FormDictionary";

import HandleTextSelection from "helpers/textSelection";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/Basics/MDButton";

import AddLabelDialog from "./labels/AddLabelDialog";
import AddChartDialog from "./charts/AddChartDialog";

const MODALS_ACTIONS = {
	ADD_LABEL: "add-label",
	ADD_CHART: "add-chart",
	FORM_DIALOG: "form-dialog",
	CONFIRM_DELETE: "confirm-delete"
};

const GloabAppSettingsComponent = ({ children }) => {
	const dispatch = useDispatch();
	const [modal, setModal] = useState({ open: false, action: null, data: {} });
	const profile = useSelector(selectCurrentProfile);

	const getModalOpen = action => {
		return modal.open && modal.action === action;
	};

	const getModalData = action => {
		if (action === modal.action) return modal.data;
		return null;
	};

	const closeModal = () => {
		setModal({ open: false, action: null, data: {} });
	};

	const handleEditChart = (code, cb) => {
		setModal({
			open: true,
			action: MODALS_ACTIONS.ADD_CHART,
			data: {
				edit: true,
				code,
				step: 3,
				cb
			}
		});
	};

	const handleAddChart = (defaultValues, cb) => {
		setModal({
			open: true,
			action: MODALS_ACTIONS.ADD_CHART,
			data: {
				edit: false,
				code: null,
				step: 0,
				defaultValues: defaultValues,
				cb
			}
		});
	};

	const insertFormItem = data => {
		const onSuccess = res => {
			setModal({
				open: true,
				action: MODALS_ACTIONS.FORM_DIALOG,
				data: {
					APIs: res.APIs,
					route: data.route,
					valuesDictionary: res.valueDictionary,
					formBuildDictionary: res.formBuilderDictionary,
					contextDictionary: res.dictionary,
					PIaccess: data.PI,
					isEmpty: data.empty,
					target: data.form.collectionDefault,
					selectedId: data.selectedId,
					routeDict: data.form.routeDictionary,
					forceEdit: Boolean(data.form.routeDictionary === "dictionary"),
					callback: data.callback
				}
			});
		};

		dispatch(
			FormActions.getItemEmpty(data.form.collectionDefault, data.form.routeDictionary, onSuccess)
		);
	};

	const updateFormItem = data => {
		const onSuccess = res => {
			setModal({
				open: true,
				action: MODALS_ACTIONS.FORM_DIALOG,
				data: {
					APIs: res.APIs,
					route: data.route,
					valuesDictionary: res.valueDictionary,
					formBuildDictionary: res.formBuilderDictionary,
					contextDictionary: res.dictionary,
					PIaccess: data.PI,
					isEmpty: data.empty,
					target: data.form.collectionDefault,
					selectedId: data.selectedId,
					routeDict: data.form.routeDictionary,
					forceEdit: Boolean(data.form.routeDictionary === "dictionary"),
					callback: data.callback
				}
			});
		};

		dispatch(
			FormActions.getItemByID(
				data.selectedId,
				data.form.collectionDefault,
				data.form.routeDictionary,
				onSuccess
			)
		);
	};

	const deleteFormItem = data => {
		const confirmDelete = () => {
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("FORMS.deleteSuccess"),
						type: "success"
					})
				);

				closeModal();
				data.callback();
			};

			dispatch(FormActions.deleteItem(data.item._id, data.target, { delete: true }, onSuccess));
		};

		setModal({
			open: true,
			action: MODALS_ACTIONS.CONFIRM_DELETE,
			data: {
				item: data.item,
				target: data.target,
				confirmDelete: confirmDelete
			}
		});
	};

	const actionSaveOrCreateItem = (values, unique, callback, rolesProfileUser) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);

			closeModal();
			getModalData(MODALS_ACTIONS.FORM_DIALOG)?.callback();
			callback(true);
		};
		let data = { values, target: getModalData(MODALS_ACTIONS.FORM_DIALOG)?.target, unique };
		if (getModalData(MODALS_ACTIONS.FORM_DIALOG)?.isEmpty) {
			data = { ...data, rolesProfileUser };
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(
					getModalData(MODALS_ACTIONS.FORM_DIALOG)?.selectedId,
					data,
					onSuccess,
					err => {
						callback(false);
					}
				)
			);
		}
	};

	const handleActionContextMenu = (action, data) => {
		switch (action) {
			case MODALS_ACTIONS.ADD_LABEL:
				setModal({
					open: true,
					action,
					data
				});
				break;
			default:
				break;
		}
	};

	return (
		<>
			{/* <HandleTextSelection handleAction={handleActionContextMenu} /> */}
			{/* Add label */}
			<AddLabelDialog
				open={getModalOpen(MODALS_ACTIONS.ADD_LABEL)}
				handleCloseDialog={closeModal}
				handleSave={({ code, label, color }) => {
					const onSuccess = res => {
						dispatch(
							display({
								message: i18n.t("SETTINGS.LABELS.SUCCESS.add"),
								type: "success"
							})
						);
					};
					dispatch(
						SettingsActions.newSetting(
							profile.assistantID,
							"label",
							{ code, label, color },
							onSuccess
						)
					);
				}}
				possibleCode={getModalData(MODALS_ACTIONS.ADD_LABEL)}
			/>
			{/* Edit chart */}
			{getModalOpen(MODALS_ACTIONS.ADD_CHART) && (
				<AddChartDialog
					open={getModalOpen(MODALS_ACTIONS.ADD_CHART)}
					handleCloseDialog={closeModal}
					handleSave={({ chart, edit }) => {
						getModalData(MODALS_ACTIONS.ADD_CHART)?.cb(chart, edit);
					}}
					edit={getModalData(MODALS_ACTIONS.ADD_CHART)?.edit}
					chart={getModalData(MODALS_ACTIONS.ADD_CHART)?.chart}
					step={getModalData(MODALS_ACTIONS.ADD_CHART)?.step}
					code={getModalData(MODALS_ACTIONS.ADD_CHART)?.code}
					defaultValues={getModalData(MODALS_ACTIONS.ADD_CHART)?.defaultValues}
				/>
			)}
			{cloneElement(children, {
				handleEditChart,
				handleAddChart,
				insertFormItem,
				updateFormItem,
				deleteFormItem
			})}
			{/* Form part */}
			{getModalOpen(MODALS_ACTIONS.FORM_DIALOG) && (
				<FormDictionaryDialog
					open={getModalOpen(MODALS_ACTIONS.FORM_DIALOG)}
					route={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.route}
					handleCloseDialog={closeModal}
					handleSave={(values, unique, callback, rolesProfileUser) => {
						actionSaveOrCreateItem(values, unique, callback, rolesProfileUser);
					}}
					listAPIs={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.APIs}
					valuesDictionary={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.valuesDictionary}
					formBuildDictionary={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.formBuildDictionary}
					contextDictionary={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.contextDictionary}
					PIaccess={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.PIaccess}
					isEmpty={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.isEmpty}
					target={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.target}
					selectedId={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.selectedId}
					routeDict={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.routeDict}
					forceEdit={getModalData(MODALS_ACTIONS.FORM_DIALOG)?.forceEdit}
				/>
			)}

			{getModalOpen(MODALS_ACTIONS.CONFIRM_DELETE) && (
				<Dialog open={getModalOpen(MODALS_ACTIONS.CONFIRM_DELETE)} onClose={closeModal}>
					<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
					<DialogContent>{i18n.t("FORMS.LABELS.confirmDelete")}</DialogContent>
					<DialogActions>
						<MDButton autoFocus onClick={closeModal} variant="outlined" color="dark">
							{i18n.t("FORMS.cancel")}
						</MDButton>
						<MDButton
							onClick={getModalData(MODALS_ACTIONS.CONFIRM_DELETE)?.confirmDelete}
							color="dark"
							variant="contained"
							autoFocus
						>
							{i18n.t("FORMS.validate")}
						</MDButton>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default GloabAppSettingsComponent;
