/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { Icon, IconButton, Tooltip } from "@mui/material";
import MethodSelect from "./Filter/MethodSelect";
import ValueSelect from "./Filter/ValueSelect";
import PathSelect from "./Filter/PathSelect";

/**
 * Row for the filters table
 * @returns
 */
const FilterTableLine = ({
	name,
	path,
	value,
	method,
	filter,
	methods,
	handleRemoveFilter,
	onChangeValue,
	dictionary
}) => {
	const [selectedMethod, setSelectedMethod] = useState(method || null);
	const [selectedValue, setSelectedValue] = useState(value || "");
	const [validFilter, setValidFilter] = useState(false);

	const valueExist = v => {
		return v !== undefined && v !== null && v !== "";
	};

	const setDefaultValue = () => {
		// default values
		switch (filter?.type) {
			case "timestamp":
				// set default value to 01:00
				setSelectedValue(1 * 60 * 60 + 0 * 60);
				break;
			case "boolean":
				setSelectedValue(Boolean(selectedValue));
				break;
			default:
				setSelectedValue(value ?? "");
				break;
		}
	};

	const setDefaultMethod = () => {
		// default values
		switch (filter?.type) {
			case "boolean":
				setSelectedMethod("$eq");
				break;
			default:
				setSelectedMethod(method ?? null);
				break;
		}
	};

	useEffect(() => {
		setDefaultMethod();
		setDefaultValue();
	}, [filter]);

	// Update row when filter change
	useEffect(() => {
		onChangeValue({
			name,
			path,
			filter,
			method: selectedMethod,
			value: selectedValue
		});

		setValidFilter(selectedMethod && valueExist(selectedValue));
	}, [selectedMethod, selectedValue]);

	useEffect(() => {
		if (selectedMethod !== method) {
			setDefaultValue();

			if (["$in", "$nin"].includes(selectedMethod)) {
				setSelectedValue([]);
			} else {
				setSelectedValue("");
			}
		}
	}, [selectedMethod]);

	return (
		<MDBox mb={2} display="flex" flexDirection="row" alignItems="stretch" style={{ width: "100%" }}>
			{/* Path */}
			<PathSelect validFilter={validFilter} path={path} dictionary={dictionary} />
			{/* Method */}
			<MethodSelect
				filter={filter}
				selectedMethod={selectedMethod}
				methods={methods}
				setSelectedMethod={setSelectedMethod}
			/>
			{/* Value */}
			<ValueSelect
				filter={filter}
				name={name}
				selectedValue={selectedValue}
				selectedMethod={selectedMethod}
				setSelectedValue={setSelectedValue}
			/>
			{/* Delete */}
			<IconButton onClick={() => handleRemoveFilter(name)}>
				<Tooltip title={i18n.t("SETTINGS.delete")} placement="top" arrow>
					<Icon>delete</Icon>
				</Tooltip>
			</IconButton>
		</MDBox>
	);
};

export default FilterTableLine;
