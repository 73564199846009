import { Fab, Icon, Slide, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";

/**
 * Floating action menu for adding, removing and removing charts.
 * @param {*} param0
 * @returns
 */
const FloatingActionsMenu = ({ edit, onClick = () => {}, handleAddChart }) => {
	/**
	 * Edition menu for a potential update
	 * */
	if (edit) {
		return (
			<MDBox
				style={{
					position: "fixed",
					bottom: 20,
					right: 20,
					zIndex: 1000,
					gap: "1rem"
				}}
				display="flex"
				flexDirection="column"
			>
				<Slide direction="up" in={edit} style={{ transformOrigin: "bottom" }}>
					<Tooltip title="Ajouter un graphique" placement="left" arrow>
						<Fab size="medium" color="info" onClick={() => handleAddChart()}>
							<Icon fontSize="small">pie_chart</Icon>
						</Fab>
					</Tooltip>
				</Slide>
				{/* <Slide direction="up" in={edit} style={{ transformOrigin: "bottom" }}>
					<Tooltip title="Supprimer la page" placement="left" arrow>
						<Fab size="medium" color="error" onClick={onClick}>
							<Icon fontSize="small">delete</Icon>
						</Fab>
					</Tooltip>
				</Slide> */}
				<Tooltip title="Fermer le menu d'édition" placement="left" arrow>
					<Fab size="medium" color="light" onClick={onClick}>
						<Icon fontSize="small">close</Icon>
					</Fab>
				</Tooltip>
			</MDBox>
		);
	} else {
		return (
			<Tooltip title="Ouvrir le menu d'édition" placement="left" arrow>
				<MDBox
					style={{
						position: "fixed",
						bottom: 20,
						right: 20,
						zIndex: 1000
					}}
				>
					<Fab size="medium" color="info" onClick={onClick}>
						<Icon fontSize="small">edit</Icon>
					</Fab>
				</MDBox>
			</Tooltip>
		);
	}
};

export default FloatingActionsMenu;
