/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
import { Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

/**
 * Edit the order of the charts
 * @param {*} param0
 * @returns
 */
const ChartEditingOrder = ({
	getChartsData,
	type,
	dragHandler,
	handleEditChart,
	handleDeleteChart = () => {},
	mouseIn = () => {},
	mouseOut = () => {}
}) => {
	const length = getChartsData(type).length;
	if (length < 2) return null;
	return (
		<MDBox flex="1" mr={2} display="flex" flexDirection="column">
			<MDTypography variant="h6" m={0} p={0}>
				Ordre des graphiques
			</MDTypography>
			<MDTypography variant="caption">Déplacez pour réorganiser</MDTypography>

			<DragDropContext
				onDragEnd={({ destination, source }) => {
					if (!source || !destination) return;
					dragHandler({ type: type, from: source.index, to: destination.index });
				}}
			>
				<Droppable droppableId="dnd-list" direction="vertical">
					{provided => (
						<MDBox {...provided.droppableProps} ref={provided.innerRef}>
							{getChartsData(type).map((chart, key) => {
								let condKey = chart.code;
								return (
									<Draggable key={condKey} index={key} draggableId={condKey}>
										{provided => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<Tooltip
													placement="right"
													title={
														<MDBox style={{ textAlign: "left" }}>
															<MDTypography
																variant="body2"
																fontSize="small"
																color="white"
																fontWeight="bold"
															>
																{chart.display.title}
															</MDTypography>
															<MDTypography
																variant="body2"
																fontSize="small"
																color="white"
																fontWeight="500"
																fontStyle="italic"
															>
																{chart.display.description}
															</MDTypography>
														</MDBox>
													}
												>
													<MDBox
														bgColor="white"
														shadow="xl"
														mt={1}
														p={1}
														borderRadius="md"
														display="flex"
														flexDirection="column"
														alignItems="end"
														onMouseEnter={() => {
															mouseIn(chart.code);
														}}
														onMouseLeave={() => {
															mouseOut(chart.code);
														}}
													>
														<MDBox
															display="flex"
															flexDirection="row"
															alignItems="center"
															// justifyContent="space-between"
															style={{ width: "100%" }}
														>
															<Icon sx={{ mr: 1 }}>drag_indicator</Icon>
															<MDTypography flex="1" variant="body2" fontSize="small">
																{chart.display.title}
															</MDTypography>
														</MDBox>
														<MDBox display="flex" flexDirection="row" alignItems="center">
															{/* Edit chart */}
															<IconButton size="small" onClick={() => handleEditChart(chart.code)}>
																<Icon fontSize="small">edit</Icon>
															</IconButton>
															{/* Delete chart */}
															<ConfirmDialogButton
																onConfirm={async (e, values) => {
																	handleDeleteChart(chart.code);
																}}
																component={
																	<IconButton color="error" size="small">
																		<Icon fontSize="small">delete</Icon>
																	</IconButton>
																}
																rowValues={chart}
																displayAttribute="display.title"
															/>
														</MDBox>
													</MDBox>
												</Tooltip>
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</MDBox>
					)}
				</Droppable>
			</DragDropContext>
		</MDBox>
	);
};

export default ChartEditingOrder;
