import {
	Box,
	Chip,
	FormControl,
	FormControlLabel,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	Switch
} from "@mui/material";
import lod_ from "lodash";
import { useEffect, useState } from "react";
import { t } from "i18next";

import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { getIconsList } from "components/Custom/IconsList";
import DictionaryButton from "components/Custom/DictionaryButton";

import { useSelector, useDispatch } from "react-redux";
import {
	selectCurrentProfile,
	selectFilteredSortedMenu
} from "redux-react/reducers/profileReducer";

import IconAccordion from "../../components/IconAccordion";
import C from "../../constants";

const ChartDisplay = ({ chart, handleChange, dictionary, disableChart, reloadChart }) => {
	const dispatch = useDispatch();

	const profile = useSelector(selectCurrentProfile);
	const pages = useSelector(selectFilteredSortedMenu);

	const [iconsList, setIconsList] = useState([]);

	const [selectedPages, setSelectedPages] = useState(
		chart.display?.pages?.map(page => "/" + page) ?? []
	);

	const POSSIBLE_SUB_TYPES = C.CHARTS_SUBTYPE[chart.type];

	const handleSwitchLabel = () => {
		handleChange("display.datalabels", !chart.display.datalabels);
	};

	const handleSwitchLegend = () => {
		handleChange("options.legend", !chart.options.legend);
	};

	// Change preview chart
	const handleChangeSelect = event => {
		let { value } = event.target;
		setSelectedPages(typeof value === "string" ? value.split(",") : value);
	};

	/**
	 * Load icons
	 */
	async function loadIcons() {
		setIconsList(await getIconsList(dispatch, profile.assistantID));
	}

	// Updates selected pages
	useEffect(() => {
		handleChange(
			"display.pages",
			selectedPages.map(page => page.replace("/", ""))
		);
	}, [selectedPages]);

	useEffect(() => {
		// Load icons
		loadIcons();
	}, []);

	return (
		<MDBox mt={2}>
			{/* Format */}
			<MDTypography variant="h6">{t("SETTINGS.CHARTS.display")}</MDTypography>
			<MDBox mt={1} display="flex">
				{POSSIBLE_SUB_TYPES.map((subType, index) => {
					return (
						<MDBox
							key={index}
							mr={1}
							bgColor={subType.code === chart.display.subType ? "info" : "light"}
							variant="gradient"
							coloredShadow={subType.code === chart.display.subType ? "info" : "light"}
							borderRadius="xl"
							className="colorPickerContainer"
							onClick={() => {
								// Need to disabled chart, then update it
								// to dont have any conflict with data
								disableChart();
								handleChange("display.subType", subType.code);
							}}
						>
							<Icon fontSize="small">{subType.icon}</Icon>
						</MDBox>
					);
				})}
			</MDBox>
			{/* Titre */}
			<MDTypography mt={2} variant="h6">
				{t("SETTINGS.CHARTS.NEW.informations")}
			</MDTypography>

			<MDBox mt={1}>
				<MDInput
					className="dialogInput"
					label={
						<span>
							{t("SETTINGS.CHARTS.NEW.chartTitle")}
							<span className="mandatoryField">*</span>
						</span>
					}
					value={chart.display.title || ""}
					onChange={e => handleChange("display.title", e.target.value)}
				/>
			</MDBox>
			{/* Description */}
			{chart.type !== "oneValue" && (
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						label={t("SETTINGS.CHARTS.NEW.chartDescription")}
						value={chart.display.description || ""}
						onChange={e => handleChange("display.description", e.target.value)}
					/>
				</MDBox>
			)}
			{/* Icon */}
			<MDBox mt={2}>
				<MDTypography variant="h6">{t("SETTINGS.CHARTS.NEW.icon")}</MDTypography>
			</MDBox>
			<MDBox display="flex" alignItems="flex-start " flexDirection="column">
				<MDBox mt={1}>
					{/* Color choice */}
					<IconAccordion
						title={
							<MDBox display="flex" alignItems="center">
								<MDBox
									mr={1}
									bgColor={chart.display.icon.color}
									variant="gradient"
									coloredShadow={chart.display.icon.color}
									borderRadius="xl"
									className="previewColorPickerContainer"
								></MDBox>
								<MDTypography variant="body1" fontSize="small">
									{t("SETTINGS.CHARTS.NEW.iconAvailableColors")}
								</MDTypography>
							</MDBox>
						}
						content={
							<MDBox display="flex" flexWrap="wrap">
								{C.ICONS_COLORS.map((color, index) => {
									return (
										<MDBox
											key={index}
											mr={1}
											bgColor={color}
											variant="gradient"
											coloredShadow={color}
											borderRadius="xl"
											className="colorPickerContainer"
											onClick={() => {
												handleChange("display.icon.color", color);
											}}
										>
											<Icon fontSize="small">{chart.display.icon.component}</Icon>
										</MDBox>
									);
								})}
							</MDBox>
						}
					/>
					{/* Icon choice */}
					<IconAccordion
						title={
							<MDBox display="flex" alignItems="center">
								<MDBox mr={1} display="flex" alignItems="center">
									<Icon fontSize="medium">{chart.display.icon.component}</Icon>
								</MDBox>
								<MDTypography variant="body1" fontSize="small">
									{t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
								</MDTypography>
							</MDBox>
						}
						content={iconsList.map((icon, index) => {
							return (
								<IconButton
									key={index}
									onClick={() => {
										let newIcon = {
											component: icon.code,
											color: chart.display.icon?.color || "info"
										};
										handleChange("display.icon", newIcon);
									}}
								>
									<Icon
										color={
											chart.display.icon.component === icon.code
												? chart.display.icon?.color || "info"
												: ""
										}
										fontSize="large"
									>
										{icon.code}
									</Icon>
								</IconButton>
							);
						})}
					/>
				</MDBox>
			</MDBox>
			{/* Pages */}
			<MDBox mt={2}>
				<MDTypography variant="h6">{t("SETTINGS.CHARTS.NEW.display")}</MDTypography>
			</MDBox>
			<MDBox mt={1}>
				<FormControl fullWidth>
					<InputLabel id="select-pages-label">
						<span>
							{t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
							<span className="mandatoryField">*</span>
						</span>
					</InputLabel>
					<Select
						labelId="select-pages-label"
						id="select-pages"
						multiple
						value={selectedPages}
						onChange={handleChangeSelect}
						input={
							<OutlinedInput
								id="select-pages-chip"
								label={
									<span>
										{t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
										<span className="mandatoryField">*</span>
									</span>
								}
							/>
						}
						renderValue={selected => (
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
								{selected.map((page, index) => {
									let goodPage = pages.find(p => p.route === page);
									if (goodPage) {
										return <Chip key={index} label={goodPage.name} />;
									} else {
										return null;
									}
								})}
							</Box>
						)}
					>
						{pages.map((page, index) => (
							<MenuItem key={index} value={page.route}>
								{page.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</MDBox>
			{/* Attributes display */}
			{["sortedList"].includes(chart.display.subType) && !chart.request.group && (
				<MDBox display="flex" mt={1}>
					<MDBox
						borderRadius="lg"
						display="flex"
						alignItems="center"
						bgColor="light"
						sx={{ width: "100%", mr: 1, p: 0.5 }}
						style={{
							border: "1px solid #d2d6da"
						}}
					>
						{!chart.request.attributesDisplay.length ? (
							<MDBox ml={1}>
								<MDTypography fontSize="small">{t("CHARTS.noFieldsToDisplay")}</MDTypography>
							</MDBox>
						) : (
							chart.request.attributesDisplay.map((attribute, index) => {
								let path = attribute.replaceAll(".", ".items.");
								let dicObject = lod_.get(dictionary[chart.request.collection].items, path);
								return (
									<Chip
										key={index}
										sx={{ mr: 0.5 }}
										label={
											<MDBox display="flex" alignItems="center">
												<MDBox>{dicObject.label.fr}</MDBox>
												<IconButton
													size="small"
													onClick={() => {
														let copyAttributesDisplay = lod_.cloneDeep(
															chart.request.attributesDisplay
														);
														copyAttributesDisplay.splice(index, 1);
														handleChange("request.attributesDisplay", copyAttributesDisplay);
													}}
												>
													<Icon>close</Icon>
												</IconButton>
											</MDBox>
										}
									/>
								);
							})
						)}
					</MDBox>
					<DictionaryButton
						sx={{ m: 0 }}
						dictionary={dictionary}
						handleClick={value => {
							value = value.split(".");
							value.shift();
							value = value.join(".");
							handleChange("request.attributesDisplay", [
								...chart.request.attributesDisplay,
								value
							]);
						}}
					/>
				</MDBox>
			)}
			<MDBox mt={2}>
				<MDTypography variant="h6">{t("SETTINGS.CHARTS.NEW.options")}</MDTypography>
			</MDBox>
			{/* ################################################ */}
			{/*	PART FOR : ONEVALUE							     */}
			{/* ################################################ */}
			{["oneValue"].includes(chart.type) && chart.display.subType !== "ranking" && (
				<>
					{/* Previous period */}
					<MDBox
						mt={1}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						className="customSwitchContainer"
						onClick={() => handleChange("request.previousPeriod", !chart.request.previousPeriod)}
					>
						<span>{t("CHARTS.periodComparison")}</span>
						<MDBox>
							<Switch checked={chart.request.previousPeriod} />
						</MDBox>
					</MDBox>
				</>
			)}
			{/* ################################################ */}
			{/*	PART FOR : ONEVALUE / ONEDIMENSION / PROPORTION  */}
			{/* ################################################ */}
			{["oneValue", "oneDimension", "proportion"].includes(chart.type) && (
				<>
					{/* Limit */}
					{chart.type !== "oneValue" && (
						<MDBox mt={1}>
							<MDInput
								value={chart.request.limit}
								type="number"
								className="dialogInput"
								label={t("SETTINGS.CHARTS.NEW.displayLimit")}
								InputProps={{ inputProps: { min: 1 } }}
								onChange={e => {
									if (e.target.value < 1) e.target.value = null;
									handleChange("request.limit", e.target.value);
								}}
							/>
						</MDBox>
					)}
					{/* Labels */}
					<MDBox mt={1}>
						<MDInput
							value={chart.display.label || ""}
							className="dialogInput"
							label={t("SETTINGS.CHARTS.NEW.displayLabel")}
							onChange={e => handleChange("display.label", e.target.value)}
						/>
					</MDBox>
					{/* Etiquettes */}
					{chart.type !== "oneValue" && (
						<MDBox
							mt={1}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							className="customSwitchContainer"
							onClick={() => handleSwitchLabel()}
						>
							<span>{t("SETTINGS.CHARTS.NEW.displayDatalabels")}</span>
							<MDBox>
								<Switch checked={chart.display.datalabels ?? false} />
							</MDBox>
						</MDBox>
					)}
					{/* Display values for % */}
					{chart.type === "proportion" && (
						<MDBox
							mt={1}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							className="customSwitchContainer"
							onClick={() => {
								handleChange("display.valueAfterPercentage", !chart.display.valueAfterPercentage);
							}}
						>
							<span>{t("SETTINGS.CHARTS.NEW.displayValueAfterPercentage")}</span>
							<MDBox>
								<Switch checked={chart.display.valueAfterPercentage ?? false} />
							</MDBox>
						</MDBox>
					)}
				</>
			)}
			{["sortedList"].includes(chart.display.subType) && (
				<MDBox
					mt={1}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					className="customSwitchContainer"
				>
					<span>{t("CHARTS.limit")}</span>
					<MDBox>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={chart.request.limit || "1"}
								onChange={e => handleChange("request.limit", e.target.value)}
							>
								<FormControlLabel value="1" control={<Radio />} label="1" />
								<FormControlLabel value="3" control={<Radio />} label="3" />
								<FormControlLabel value="5" control={<Radio />} label="5" />
								<FormControlLabel value="10" control={<Radio />} label="10" />
								<FormControlLabel value="20" control={<Radio />} label="20" />
								<FormControlLabel value="50" control={<Radio />} label="50" />
								<FormControlLabel value="null" control={<Radio />} label={t("CHARTS.all")} />
							</RadioGroup>
						</FormControl>
					</MDBox>
				</MDBox>
			)}
			{/* Sort  */}
			{["proportion", "oneDimension"].includes(chart.type) && (
				<MDBox
					mt={1}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					className="customSwitchContainer"
				>
					<span>{t("CHARTS.sort")}</span>
					<MDBox>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={chart.request.sort || "none"}
								onChange={e => handleChange("request.sort", e.target.value)}
							>
								<FormControlLabel value="none" control={<Radio />} label={t("CHARTS.SORT.none")} />
								<FormControlLabel value="asc" control={<Radio />} label={t("CHARTS.SORT.asc")} />
								<FormControlLabel value="desc" control={<Radio />} label={t("CHARTS.SORT.desc")} />
							</RadioGroup>
						</FormControl>
					</MDBox>
				</MDBox>
			)}
			{/* Sort for sortedlist */}
			{["sortedList", "ranking"].includes(chart.display.subType) && (
				<MDBox
					mt={1}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					className="customSwitchContainer"
				>
					<span>{t("CHARTS.sort")}</span>
					<MDBox>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={chart.request.sort || "desc"}
								onChange={e => handleChange("request.sort", e.target.value)}
							>
								<FormControlLabel value="asc" control={<Radio />} label={t("CHARTS.SORT.asc")} />
								<FormControlLabel value="desc" control={<Radio />} label={t("CHARTS.SORT.desc")} />
							</RadioGroup>
						</FormControl>
					</MDBox>
				</MDBox>
			)}
			{/* ################################################ */}
			{/*	PART FOR : TIMESERIES / CrossTabel (no table)	 */}
			{/* ################################################ */}
			{["timeSeries", "crossTable"].includes(chart.type) &&
				!["table"].includes(chart.display.subType) && (
					<>
						{/* Legende */}
						<MDBox
							mt={1}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							className="customSwitchContainer"
							onClick={() => handleSwitchLegend()}
						>
							<span>{t("CHARTS.displayLegend")}</span>
							<MDBox>
								<Switch checked={chart.options.legend || true} />
							</MDBox>
						</MDBox>
					</>
				)}
		</MDBox>
	);
};

export default ChartDisplay;
